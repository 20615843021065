<template>
  <b-card>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form @submit.prevent="handleSubmit(onSubmit)">
        <div class="d-flex">
          <feather-icon icon="SlidersIcon" size="19"/>
          <h4 class="mb-0 ml-50">Onay Süreci Bilgileri</h4>
        </div>

        <b-row class="mt-1">

          <b-col cols="12" md="6" lg="6">
            <validation-provider #default="validationContext" name="İsim" rules="required">
              <b-form-group label="* İsim" :state="getValidationState(validationContext)">
                <b-form-input trim placeholder="İsim" v-model="dataInfo.name"/>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="6" lg="6">
            <validation-provider #default="validationContext" name="Durum" rules="required">
              <b-form-group label="Durum" label-for="isActive" :state="getValidationState(validationContext)">
                <v-select v-model="dataInfo.isActive" :options="statusOptions" :reduce="val => val.value" :clearable="false"/>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="12" lg="12">
            <validation-provider #default="validationContext" name="Açıklama">
              <b-form-group label="Açıklama">
                <b-form-textarea v-model="dataInfo.description" rows="2" placeholder="Açıklama..."/>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

        </b-row>

        <div class="d-flex">
          <feather-icon icon="SlidersIcon" size="19"/>
          <h4 class="mb-0 ml-50">Onay Adımları</h4>
        </div>

        <div class="mt-1">
          <b-row class="border-bottom border-top p-1">
            <b-col cols="12" md="4" lg="4" class="pl-0">
              <b-button variant="primary" @click="newStepModal">
                <feather-icon icon="PlusSquareIcon" size="16"/>
                <span class="align-middle" role="button"> Ekle</span>
              </b-button>
            </b-col>
          </b-row>

          <div class="pt-1">

            <div v-for="(step, index) in dataInfo.stepList" :key="index" class="d-flex justify-content-between align-items-center border-bottom py-1" v-if="!step.isDeleted">
              <div class="d-flex justify-content-between align-items-center">
                <b-avatar size="32" class="mr-50" :text="step.stepNo.toString()" :variant="`light-primary`"/>
                <div class="user-page-info">
                  <h6 class="mb-0">
                    {{ step.confirmationTypeName }}
                  </h6>
                  <small class="text-muted">{{ step.confirmationTypeValueName }}</small>
                </div>
              </div>
              <div>
                <b-button variant="warning" class="btn-icon" @click="editStep(step)">
                  <feather-icon icon="EditIcon"/>
                  Düzenle
                </b-button>
                <b-button variant="danger" class="btn-icon ml-1" @click="removeStep(step)">
                  <feather-icon icon="XIcon"/>
                  Çıkar
                </b-button>
              </div>
            </div>

          </div>

        </div>

        <b-modal v-model="modalShow" title="Onay Bilgileri" ok-title="Kaydet" cancel-title="Vazgeç" cancel-variant="outline-secondary" @show="resetModal" @hidden="resetModal" @ok="handleModal">
          <form ref="form" @submit.stop.prevent="submitModal">

            <b-form-group label="Adım No">
              <b-form-input trim placeholder="Adım No" v-model="stepInfo.stepNo"/>
            </b-form-group>

            <b-form-group label="Onay Tipi">
              <v-select v-model="stepInfo.confirmationTypeId" :options="formConfirmationTypeOptions" :reduce="val => val.value" :clearable="false"/>
            </b-form-group>

            <b-form-group label="Kullanıcı" v-if="stepInfo.confirmationTypeId === 'USER'">
              <v-select v-model="stepInfo.confirmationTypeValueId" :clearable="false" :options="userOptions" label="label" :reduce="val => val.value">
                <template #option="{ avatar, label }">
                  <b-avatar size="sm" :src="getApiFile(avatar)" :text="(label!=null && !busy ? avatarText(label) : '')"/>
                  <span class="ml-50 align-middle"> {{ label }}</span>
                </template>
                <template #selected-option="{ avatar, label }">
                  <b-avatar size="sm" class="border border-white" :src="getApiFile(avatar)" :text="(label!=null && !busy ? avatarText(label) : '')"/>
                  <span class="ml-50 align-middle"> {{ label }}</span>
                </template>
              </v-select>
            </b-form-group>

            <b-form-group label="Pozisyon" v-if="stepInfo.confirmationTypeId === 'POSITION'">
              <v-select v-model="stepInfo.confirmationTypeValueId" :options="positionOptions" :reduce="val => val.value" :clearable="false"/>
            </b-form-group>

            <b-form-group label="Onaylandı ise, bildirim gönderilsin">
              <v-select v-model="stepInfo.sendConfirmationMail" :options="yesNoOptions" :reduce="val => val.value" :clearable="false"/>
            </b-form-group>

            <b-form-group label="Durum">
              <v-select v-model="stepInfo.isActive" :options="statusOptions" :reduce="val => val.value" :clearable="false"/>
            </b-form-group>

          </form>
        </b-modal>

        <action-buttons :back-route="'definition-workflow-list'"/>

      </b-form>
    </validation-observer>
    <Overlay :busy="busy"></Overlay>
  </b-card>
</template>

<script>
import {BAvatar, BButton, BCard, BCol, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BFormTags, BFormTextarea, BModal, BRow, BTable} from 'bootstrap-vue'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import {email, required} from '@validations'
import {onUnmounted, ref} from '@vue/composition-api'
import vSelect from 'vue-select'
import store from "@/store"
import storeModule from "@/views/definition/workflow/store"
import positionModule from "@/views/organization/position/store"
import userModule from "@/views/organization/employee/store"
import definitionModule from "@/views/system/definition/store"
import router from '@/router'
import {useToast} from 'vue-toastification/composition'
import {avatarText, getApiFile, statusOptions, yesNoOptions} from "@core/utils/filter"
import Overlay from "@/components/Overlay.vue"
import ActionButtons from "@/components/Form/ActionButtons.vue"
import {toastMessage} from "@core/utils/utils"
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue"
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue"
import Vue from "vue"

export default {
  components: {
    BFormTags,
    BAvatar,
    BModal,
    BTable,
    BFormTextarea,
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BFormInvalidFeedback,

    AppCollapseItem,
    AppCollapse,
    ActionButtons,
    Overlay,
    vSelect,

    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      required,
      email,
    }
  },
  setup() {
    const STORE_MODULE_NAME = 'store'
    const STORE_DEFINITION_MODULE_NAME = 'store-definition'
    const STORE_POSITION_MODULE_NAME = 'store-position'
    const STORE_USER_MODULE_NAME = 'store-user'

    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, storeModule)
      store.registerModule(STORE_DEFINITION_MODULE_NAME, definitionModule)
      store.registerModule(STORE_POSITION_MODULE_NAME, positionModule)
      store.registerModule(STORE_USER_MODULE_NAME, userModule)
    }
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) {
        store.unregisterModule(STORE_MODULE_NAME)
        store.unregisterModule(STORE_DEFINITION_MODULE_NAME)
        store.unregisterModule(STORE_POSITION_MODULE_NAME)
        store.unregisterModule(STORE_USER_MODULE_NAME)
      }
    })

    const toast = useToast()
    const busy = ref(false)

    const dataInfo = ref({
      id: null,
      name: '',
      description: '',
      isActive: true,
      stepList: []
    })

    const stepInfo = ref({
      id: null,
      stepNo: 0,
      confirmationTypeId: null,
      confirmationTypeValueId: null,
      sendConfirmationMail: true,
      isActive: true,
      isDeleted: false,
      confirmationTypeName: '',
      confirmationTypeValueName: ''
    })
    const stepEditInfo = ref(null)
    const modalShow = ref(false)

    busy.value = true
    const formConfirmationTypeOptions = ref([])
    store.dispatch('store-definition/definitionList', {groupKey: 'WORKFLOW_CONFIRMATION_TYPE'}).then(response => {
      response.data.data.forEach((value, index) => {
        formConfirmationTypeOptions.value.push({label: value.name, value: value.key})
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    const positionOptions = ref([])
    busy.value = true
    store.dispatch('store-position/fetchItems').then(response => {
      response.data.data.forEach((value, index) => {
        if (value.isActive) {
          positionOptions.value.push({label: value.code + ' - ' + value.name, value: value.id})
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    const userOptions = ref([])
    busy.value = true
    store.dispatch('store-user/fetchItems').then(response => {
      response.data.data.forEach((value, index) => {
        if (value.isActive) {
          if (!userOptions.value.some(e => e.value === value.id)) {
            userOptions.value.push({label: value.firstName + ' ' + value.lastName, value: value.id, avatar: value.profilePicture})
          }        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    const onSubmit = () => {
      busy.value = true
      store.dispatch('store/' + (router.currentRoute.params.id > 0 ? 'editItem' : 'addItem'), dataInfo.value).then(response => {
        toastMessage(toast, 'success', response.data.message)
        router.push({name: 'definition-workflow-list'})
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.message)
      }).finally(message => {
        busy.value = false
      })
    }

    const newStepModal = () => {
      stepInfo.value = {
        id: null,
        stepNo: 0,
        confirmationTypeId: null,
        confirmationTypeValueId: null,
        sendConfirmationMail: true,
        isActive: true,
        isDeleted: false,
        confirmationTypeName: '',
        confirmationTypeValueName: ''
      }

      modalShow.value = true
    }

    const resetModal = () => {
    }

    const submitModal = () => {
      let confirmationTypeName = '';
      formConfirmationTypeOptions.value.forEach((value, index) => {
        if (value.value === stepInfo.value.confirmationTypeId) {
          confirmationTypeName = value.label
        }
      });

      let confirmationTypeValueName = '';
      if (stepInfo.value.confirmationTypeId === 'USER') {
        userOptions.value.forEach((value, index) => {
          if (value.value === stepInfo.value.confirmationTypeValueId) {
            confirmationTypeValueName = value.label
          }
        });
      }

      if (stepInfo.value.confirmationTypeId === 'POSITION') {
        positionOptions.value.forEach((value, index) => {
          if (value.value === stepInfo.value.confirmationTypeValueId) {
            confirmationTypeValueName = value.label
          }
        });
      }

      let isAppendItem = true

      dataInfo.value.stepList.forEach((value, index) => {
        if (value === stepEditInfo.value) {
          isAppendItem = false
          value.id = stepInfo.value.id
          value.stepNo = stepInfo.value.stepNo
          value.confirmationTypeId = stepInfo.value.confirmationTypeId
          value.confirmationTypeName = confirmationTypeName
          value.confirmationTypeValueId = stepInfo.value.confirmationTypeValueId
          value.confirmationTypeValueName = confirmationTypeValueName
          value.sendConfirmationMail = stepInfo.value.sendConfirmationMail
          value.isActive = stepInfo.value.isActive
          value.isDeleted = stepInfo.value.isDeleted
        }
      });

      if (isAppendItem) {
        dataInfo.value.stepList.push({
          id: stepInfo.value.id,
          stepNo: stepInfo.value.stepNo,
          confirmationTypeId: stepInfo.value.confirmationTypeId,
          confirmationTypeName: confirmationTypeName,
          confirmationTypeValueId: stepInfo.value.confirmationTypeValueId,
          confirmationTypeValueName: confirmationTypeValueName,
          sendConfirmationMail: stepInfo.value.sendConfirmationMail,
          isActive: stepInfo.value.isActive,
          isDeleted: stepInfo.value.isDeleted,
        })
      }

      stepInfo.value = {
        id: null,
        stepNo: 0,
        confirmationTypeId: null,
        confirmationTypeValueId: null,
        sendConfirmationMail: true,
        isActive: true,
        isDeleted: false,
        confirmationTypeName: '',
        confirmationTypeValueName: ''
      };

      modalShow.value = false
    }

    const handleModal = (bvModalEvt) => {
      bvModalEvt.preventDefault()
      submitModal()
    }

    const removeStep = (stepData) => {
      Vue.swal({
        title: 'Onaylıyor musunuz?',
        text: 'Kayıt çıkarılacaktır!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet, Çıkar',
        cancelButtonText: 'Vazgeç',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          dataInfo.value.stepList.forEach((value, index) => {
            if (value === stepData) {
              value.isDeleted = true
            }
          });
        }
      })
    }

    const editStep = (stepData) => {
      stepEditInfo.value = stepData

      stepInfo.value = {
        id: stepData.id,
        stepNo: stepData.stepNo,
        confirmationTypeId: stepData.confirmationTypeId,
        confirmationTypeName: stepData.confirmationTypeName,
        confirmationTypeValueId: stepData.confirmationTypeValueId,
        confirmationTypeValueName: stepData.confirmationTypeValueName,
        sendConfirmationMail: stepData.sendConfirmationMail,
        isActive: stepData.isActive,
        isDeleted: stepData.isDeleted,
      };

      modalShow.value = true
    }

    if (router.currentRoute.params.id > 0) {
      busy.value = true
      store.dispatch('store/fetchItem', {id: router.currentRoute.params.id}).then(response => {
        dataInfo.value = response.data.data
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.message)
        if (error.response.status === 403) {
          router.push({name: 'definition-workflow-list'})
        }
      }).finally(message => {
        busy.value = false
      })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    return {
      busy,
      dataInfo,
      refFormObserver,
      positionOptions,
      formConfirmationTypeOptions,
      modalShow,
      stepInfo,
      userOptions,

      statusOptions,
      yesNoOptions,

      onSubmit,
      getValidationState,
      resetForm,
      newStepModal,
      resetModal,
      submitModal,
      handleModal,
      removeStep,
      editStep,
      avatarText,
      getApiFile,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
